<template>
  <div class="study-timeline">
    <!-- <div>
      <h4 class="dashboard__heading">Student Abroad Journey</h4>
    </div> -->
    <b-card title="Student Abroad Journey">
      <app-timeline>
        <app-timeline-item variant="success">
          <div class="study-timeline__grid">
            <app-collapse>
              <app-collapse-item title="Title">
                <template #header>
                  <h6 class="study-timeline__text">Application Under assessment</h6>
                </template>
                <ul>
                  <li>Profile Update</li>
                  <li>Documents Upload</li>
                  <li>Application Finalised</li>
                </ul>
              </app-collapse-item>
            </app-collapse>
          </div>
        </app-timeline-item>
        <app-timeline-item variant="success">
          <div class="study-timeline__grid">
            <app-collapse>
              <app-collapse-item title="Title">
                <template #header>
                  <h6 class="study-timeline__text">Applied to university</h6>
                </template>
                <ul>
                  <li>Application under review</li>
                  <li>Pending Document submission</li>
                  <li>Application Decision</li>
                </ul>
              </app-collapse-item>
            </app-collapse>
          </div>
        </app-timeline-item>
        <app-timeline-item variant="success">
          <div class="study-timeline__grid">
            <app-collapse>
              <app-collapse-item title="Title">
                <template #header>
                  <h6 class="study-timeline__text">
                    Conditional Offer letter Received
                  </h6>
                </template>
                <ul>
                  <li>Conditions to fulfil</li>
                  <li>DInterview to Clear</li>
                  <li>Advance fee Payment</li>
                </ul>
              </app-collapse-item>
            </app-collapse>
          </div>
        </app-timeline-item>
        <app-timeline-item variant="success">
          <div class="study-timeline__grid">
            <app-collapse>
              <app-collapse-item title="Title">
                <template #header>
                  <h6 class="study-timeline__text">Advance Fee Paid</h6>
                </template>
                <ul>
                  <li>Fund Documentation</li>
                  <li>Accommodation Finalisation</li>
                </ul>
              </app-collapse-item>
            </app-collapse>
          </div>
        </app-timeline-item>
        <app-timeline-item variant="success">
          <div class="study-timeline__grid">
            <app-collapse>
              <app-collapse-item title="Title">
                <template #header>
                  <h6 class="study-timeline__text">
                    Unconditional Offer letter Received
                  </h6>
                </template>
                <ul>
                  <li>Balance fee payment if any</li>
                  <li>Visa Documentation</li>
                </ul>
              </app-collapse-item>
            </app-collapse>
          </div>
        </app-timeline-item>
        <app-timeline-item variant="success">
          <div class="study-timeline__grid">
            <app-collapse>
              <app-collapse-item title="Title">
                <template #header>
                  <h6 class="study-timeline__text">
                    Visa Applied
                  </h6>
                </template>
                <ul>
                  <li>Visa Decision</li>
                  <li>Pre Departure Arrangements</li>
                </ul>
              </app-collapse-item>
            </app-collapse>
          </div>
        </app-timeline-item>
      </app-timeline>
      <!--/ timeline -->
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BImg,
  BMedia,
  BMediaBody,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  BProgress,
  VBTooltip,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardHeader,
    BCardTitle,
    AppTimeline,
    AppTimelineItem,
    AppCollapse,
    AppCollapseItem,
    BProgress,

    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style>
.study-timeline__grid {
  display: grid;
  grid-template-columns: 100%;
}
.study-timeline__grid .card-header {
 padding: 0 !important;
}
.study-timeline__grid ul {
 padding: 0 !important;
 margin: 0 !important;
 font-weight: 600 !important;
}
.study-timeline .study-timeline__text {
  color: #000;
  font-weight: bold;
  font-size: 1.2rem;
}
.circle-progress {
  width: 70px;
  height: 70px;
  /* line-height: 150px; */
  background: none;
  box-shadow: none;
  position: relative;
}
.circle-progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 08px solid rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
}
.circle-progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.circle-progress .circle-progress-left {
  left: 0;
}
.circle-progress .circle-progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 08px;
  border-style: solid;
  position: absolute;
  top: 0;
}
.circle-progress .circle-progress-left .circle-progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.circle-progress .circle-progress-right {
  right: 0;
}
.circle-progress .circle-progress-right .circle-progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}
.circle-progress .circle-progress-value {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: #5367ff;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  line-height: 68px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%;
}
.circle-progress.blue .circle-progress-bar {
  border-color: #00d09c;
}
.circle-progress.blue .circle-progress-left .circle-progress-bar {
  animation: loading-2 1.5s linear forwards 1.8s;
}
.circle-progress.yellow .circle-progress-bar {
  border-color: #fdba04;
}
.circle-progress.yellow .circle-progress-left .circle-progress-bar {
  animation: loading-3 1s linear forwards 1.8s;
}
.circle-progress.pink .circle-progress-bar {
  border-color: #ed687c;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(144deg);
    transform: rotate(144deg);
  }
}

@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}
</style>
