<template>
  <b-card
    v-if="data"
    no-body
  >
    <b-row class="text-center mx-0">
      <b-col
        cols="6"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          Course Shortlisted
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ data.course_shortlisted }}
        </h3>
      </b-col>

      <b-col
        cols="6"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          Applications
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ data.applications }}
        </h3>
      </b-col>

      <b-col
        cols="6"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          Offer Letters Received
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ data.offer_letters_received }}
        </h3>
      </b-col>

      <b-col
        cols="6"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          Awaiting Offer Letter
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ data.awaiting_offer_letter }}
        </h3>
      </b-col>

     
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BRow, BCol, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    }
  },
}
</script>
