<template>
  <b-card title="Refer Your Friend">
    <validation-observer ref="addReference">
      <b-form @submit.stop.prevent="saveReference">
        <b-form-group label="Name">
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required"
          >
            <b-form-input v-model="form.name" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Email">
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|email"
          >
            <b-form-input type="email" v-model="form.email" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Mobile Number">
          <validation-provider
            #default="{ errors }"
            name="Mobile Number"
            rules="required"
          >
            <b-form-input v-model="form.mobile" type="number" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-button type="submit"> Send </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { BCard, BForm, BFormGroup, BFormInput, BButton } from "bootstrap-vue";
import StudentServices from "@/apiServices/StudentServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import { required, email } from "@validations";
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";
setInteractionMode("passive");

export default {
  components: {
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        mobile: "",
      },
      required,
      email,
    };
  },
  methods: {
    async saveReference() {
      const validation = await this.$refs.addReference.validate();
      if (!validation) return;
      const res = await StudentServices.saveReferenceLead(this.form);
      if (res.data.status) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: res.data.message || "Success",
            icon: "EditIcon",
            variant: "success",
          },
        });
      }
      this.resetForm();
    },
    resetForm() {
      this.form = {
        name: "",
        email: "",
        mobile: "",
      };
    },
  },
};
</script>

<style>
</style>